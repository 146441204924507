<template>
  <PageWithHeader header-class="bg-primary c-white">
    <template #title>
      <t path="levelandpoints_2">Level and Points</t>
    </template>
    <div class="bg-primary c-white px-16 pt-16 pb-32 df-middle">
      <Pic src="membership/about" width="88" height="88" />
      <t as="p" class="ml-16 f-xl pr-16 f-bold header-text" path="membershipintro_2">
        All you need to know about WeTrade membership
      </t>
    </div>
    <div class="px-16 py-32">
      <div v-for="(item, index) in list" :key="index">
        <div class="d-f mb-16">
          <Pic src="membership/question" width="24" height="24" class="mr-16" />
          <t as="p" :path="item.question" class="f-bold f-lg question">--</t>
        </div>
        <div class="d-f">
          <Pic src="membership/answer" width="24" height="24" class="mr-16" />
          <t as="p" :path="item.answer" class="f-md answer">--</t>
        </div>
        <Line class="my-16" />
      </div>
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Line from '@/components/Line.vue'

export default defineComponent({
  name: 'AboutMember',
  components: { Line, PageWithHeader, Pic },
  setup () {
    return {
      list: [{
        question: 'membershipintro_3',
        answer: 'membershipintro_4',
      }, {
        question: 'membershipintro_5',
        answer: 'membershipintro_6',
      }, {
        question: 'membershipintro_7',
        answer: 'membershipintro_8',
      }],
    }
  },
})
</script>
<style scoped lang="scss">
  .header-text {
    line-height: 24px;
  }

  .question {
    line-height: 24px;
  }

  .answer {
    line-height: 18px;
  }
</style>
